import { request, getJSON } from './request';
const gatewayUrl = process.env.GATEWAY_URL;
const LOGIN_PREFIX = 'ProxyLogin';
const LAPI_PREFIX = 'ProxyLapi';

export const loginAction = (params) => request('/Auth/access', 'POST', params, LOGIN_PREFIX);
export const logoutAction = (params) => request('/Logout/logout', 'GET', params, LOGIN_PREFIX);
export const getCodeAction = (params) => request('/Sms/getBg', 'GET', params, LOGIN_PREFIX);
export const changePwdAction = (params) => request('/Register/resetPassword', 'POST', params, LOGIN_PREFIX);
export const getRefundOrderByApAction = (params) => request('/admin/Live/getRefundOrderByAp', 'GET', params);
export const getLiveOrderListAction = (params) => request('/admin/Live/getOrderList', 'GET', params);
export const liveRefundAction = (params) => request('/admin/Live/addRefund', 'POST', params);

export const getOrderSubclass = (params) => request('/admin/Live/getSubclassesByOrderNo', 'GET', params);

export const getLogsAction = (params) => request('/admin/log/get', 'GET', params);

// 系统设置
export const getSystemLimits = (params) => request('/admin/Settings/getLimit', 'GET', params);
export const updateSystemLimits = (params) => request('/admin/Settings/saveLimit', 'GET', params);
// 系统设置->支付主体配置
export const getPaymentChannels = (params) => request('/admin/Paymentchannelconfig/getPaymentChannels', 'GET', params);
export const getpaymentchannelconfig = (params) => request('/admin/Paymentchannelconfig/get', 'GET', params);
export const updatepayment = (params) => request('/admin/Paymentchannelconfig/update', 'POST', params);
// 联报优惠配置
export const getAllLiveClass = (params) => request('/admin/Live/getLive', 'GET', params);
export const getOneClass = (params) => request('/Live/getClassInfo', 'GET', params);
export const addBigClass = (params) => request('/admin/Live/judgeUnion', 'POST', params);
export const getAllMaterial = (params) => request('/admin/Material/getAll', 'GET', params);
export const createUnion = (params) => request('/admin/Live/createUnion', 'POST', params);
export const getUnion = (params) => request('/admin/Live/getUnionList', 'GET', params);
export const editUnion = (params) => request('/admin/Live/examineUnion', 'POST', params);
export const deleteUnionApi = (params) => request('/admin/Live/deleteUnion', 'POST', params);
export const getAllUnionClass = () => request('/admin/Live/getUnionGoodsList', 'GET');
// 联报模版
export const createLBModel = (params) => request('/admin/Marketing/createUnionTemplate', 'POST', params);
export const getLBModelList = (params) => request('/admin/Marketing/getUnionTemp', 'GET', params);
export const setUnionIsVisible = (params) => request('/admin/Marketing/setClassUnion', 'POST', params);

// 添加知识点、刷题和弹窗广告配置页面
export const getLiveBanners = (params) => request('/admin/Classcenter/getAllAds', 'GET', params);
export const saveLiveBanners = (params) => request('/admin/Classcenter/createAd', 'POST', params);
export const editLiveBanner = (params) => request('/admin/Classcenter/editAd', 'POST', params);
export const deleteLiveBanner = (params) => request('/admin/Classcenter/deleteAd', 'GET', params);
export const adutmconfigList = (params) => request('/admin/Adutmconfig/getList', 'GET', params);
export const adutmconfigDel = (params) => request('/admin/Adutmconfig/del', 'GET', params);
export const uploadImgConfigBanner = (params) => request('/admin/Classcenter/uploadAd', 'POST', params);
export const switchOnlineBanner = (params) => request('/admin/Classcenter/switchOnline', 'POST', params);
export const adutmconfigSave = (params) => request('/admin/Adutmconfig/save', 'POST', params);

// 年卡会员
export const getUserVip = (params) => request('/admin/Uservip/getUserVip', 'GET', params);
export const getSales = (params) => request('/admin/Uservip/getSales', 'GET', params);
export const getYearCardMembers = (params) => request('/admin/User/findUsers/1', 'POST', params);
export const addUserVip = (params) => request('/admin/Uservip/addUserVip', 'POST', params);
export const upUserVip = (params) => request('/admin/Uservip/upUserVip', 'POST', params);
export const editUserVipApply = (params) => request('/admin/Uservip/editUserVipApply', 'POST', params);
export const getUserVipApply = (params) => request('/admin/Uservip/getUserVipApply', 'GET', params);
export const upUserVipApply = (params) => request('/admin/Uservip/upUserVipApply', 'GET', params);
// 长期班管理
export const offlineList = (params) => request('/admin/Offline/get', 'GET', params);
export const editOffline = (params) => request('/admin/Classgroup/getClassGroupItem', 'GET', params);
export const updateEditOffline = (params) => request('/admin/Offline/update', 'POST', params);
export const getSmallClassList = (params) => request('/admin/Offline/getSubclasses', 'get', params);
export const getOfflineUsers = (params) => request('/admin/Offline/offlineUsers', 'GET', params);
export const getConflictLists = (params) => request('/admin/Classgroup/getConflicts', 'GET', params);
export const deleteConflict = (params) => request('/admin/Classgroup/deleteConflict', 'GET', params);
export const offlineSearch = (params) => request('/admin/Offline/search', 'GET', params);
export const createUserOfflineBatch = (params) => request('/admin/Coupon/createUserOfflineBatch', 'POST', params);
export const createUserOffline = (params) => request('/admin/Coupon/createUserOffline', 'POST', params);
export const getCouponOffline = (params) => request('/admin/Coupon/getCouponOffline', 'GET', params);
export const voidOfflineSubclass = (params) => request('/admin/Coupon/voidOfflineSubclass', 'POST', params);
export const updateSubclass = (params) => request('/admin/Offline/updateSubclass', 'POST', params);
// 长期班直播课
export const getLiveList = (params) => request('/admin/Subclass/getRecentEnd', 'GET', params);
// export const createUpload = (params) => request('/admin/Video/createUploadAuth', 'POST', params);
export const deleteVideo = (params) => request('/admin/Video/delete', 'GET', params);
// 平台列表
export const Thirdplatform = (params) => request('/admin/Thirdplatform/getList', 'GET', params);
export const ThirdplatformCreate = (params) => request('/admin/Thirdplatform/create', 'POST', params);
export const ThirdplatformUpdate = (params) => request('/admin/Thirdplatform/update', 'POST', params);
export const ThirdplatformDelete = (params) => request('/admin/Thirdplatform/delete', 'POST', params);
// 公众号自动回复消息配置
export const Officialaccount = (params) => request('/admin/Officialaccount/getPlansList', 'GET', params);
export const OfficialaccountUploadImg = (params) => request('/admin/Officialaccount/uploadImg', 'POST', params);
export const OfficialaccountCreatePlan = (params) => request('/admin/Officialaccount/createPlan', 'POST', params);
export const OfficialaccountEditPlan = (params) => request('/admin/Officialaccount/editPlan', 'POST', params);
export const OfficialaccountDeletePlan = (params) => request('/admin/Officialaccount/deletePlan', 'GET', params);
export const OfficialaccountSwitchOnline = (params) => request('/admin/Officialaccount/switchOnline', 'GET', params);
// 学习计划配置
export const getStudyplanConfigure = (params) => request('/admin/Studyplan/list', 'GET', params);
export const addStudyplanConfigure = (params) => request('/admin/Studyplan/add', 'POST', params);
export const editStudyplanConfigure = (params) => request('/admin/Studyplan/edit', 'POST', params);
// 反馈
export const getFeedbackHistory = (params) => request('/v1/admin/feedback/getHistory', 'GET', params);

// 全局
export const subjectGetList = (params) => request('/Subject/getList', 'GET', params);

// 教师公开页
export const addGonkaiClass = (params) => request('/admin/Teacher/createVideo', 'POST', params);
export const deleteTeacherVideo = (params) => request('/admin/Teacher/deleteVideo', 'POST', params);
export const updateTeacherVideo = (params) => request('/admin/Teacher/updateVideo ', 'POST', params);
export const getTeacherVideo = (params) => request('/admin/Teacher/getVideos', 'GET', params);
export const getFreeLiveSubclass = (params) => request('/admin/Teacher/getFreeLive', 'GET', params);

export const addRecommendClass = (params) => request('/admin/Teacher/createRecommendClass', 'POST', params);
export const getRecommendClass = (params) => request('/admin/Teacher/getRecommendClasses', 'GET', params);
export const upRecommendClass = (params) => request('/admin/Teacher/updateRecommendClass', 'POST', params);
export const delRecommendClass = (params) => request('/admin/Teacher/deleteRecommendClass', 'POST', params);

// 在线考试阅卷
export const getLiveStatus = (params) => request('/admin/live/getLiveStatus', 'GET', params);
// 获取正在推流的列表
export const getLivingClassList = (params) => request('/admin/Subclass/getLivingClassList', 'GET', params);
// 题目组
export const getQuetiongroupList = (params) => request('/v1/admin/Questiongroup/list', 'GET', params);
export const createQuestiongroup = (params) => request('/v1/admin/Questiongroup/create', 'POST', params);
export const updateQuestiongroup = (params) => request('/v1/admin/Questiongroup/update', 'POST', params);
export const getUploadQuestionInfo = (params) => request('/v1/admin/Questiongroup/getUploadQuestionInfo', 'POST', params);
// 在线考试阅卷
export const getUserQuestionsList = (params) => request('/admin/Exam/getUserQuestionsList', 'GET', params);
export const getManualUserQuestionsList = (params) => request(`${gatewayUrl}/ivx/forward/api/11497195/hand_paper`, 'GET', params);
export const getUserQuestionsPicList = (params) => request('/admin/Exam/getUserQuestionsPicList', 'GET', params);
export const getUserQuestionsObjectiveList = (params) => request('/v1/admin/Exam/getObjectiveQuestions', 'GET', params);
export const giveUserQuestionsScore = (params) => request('/admin/Exam/giveUserQuestionsScore', 'POST', params);
export const reviewTotalMark = (params) => request(`${gatewayUrl}/admin/v1/Exam/reviewTotalMark`, 'POST', params);
// 在线考试阅卷-试卷分析
export const getUserQuestionsAnalyseList = (params) => request('/admin/Exam/getUserExamQuestions', 'GET', params);
export const saveUserAnalysis = (params) => request('/admin/Exam/saveUserAnalysis', 'POST', params);
export const submitAnalysis = (params) => request(`${gatewayUrl}/admin/v1/Exam/submitAnalysis`, 'POST', params);

// 直播礼物配置
export const getLiveGiftConfList = (params) => request('/admin/Liveroomgift/gifts', 'GET', params);
export const addLiveGiftConf = (params) => request('/admin/Liveroomgift/create', 'POST', params);
export const upLiveGiftConf = (params) => request('/admin/Liveroomgift/update', 'POST', params);
export const delLiveGiftConf = (params) => request('/admin/Liveroomgift/delete', 'POST', params);

// 同步直播小课
export const syncSubclassLiveVideo = (params) => request('/admin/College/syncSubclassLiveVideo', 'POST', params);

// 批量生成账号
export const batchGenCollegeUser = (params) => request('/admin/User/batchGenCollegeUser', 'GET', params);
// 上传学院用户账号
export const uploadCollegeUser = (params) => request('/admin/User/uploadCollegeUser', 'POST', params);

// 团购配置
export const getGroupsInfo = (params) => request('/admin/Marketing/getGroupsInfo', 'GET', params);
export const getListForMarketing = (params) => request('/admin/Offline/getListForMarketing', 'GET', params);
export const saveGroup = (params) => request('/admin/Marketing/saveGroup', 'POST', params);

// 营销管理
export const createCouponGrant = (params) => request('/admin/Marketing/createCouponGrant', 'POST', params);
export const checkCoupon = (params) => request('/admin/Coupon/check', 'POST', params);
export const createDiscountCoupon = (params) => request('/admin/Coupon/createDiscountCoupon', 'POST', params);
export const getCouponList = (params) => request('/admin/Coupon/getList', 'GET', params);
export const getCouponUseList = (params) => request('/admin/Coupon/getCouponUseStatistics', 'GET', params);
export const getLiveLabel = (params) => request('/v2/Labels/getLiveLabel', 'GET', params);
export const liveSearch = (params) => request('/live/search', 'GET', params);
export const getOfflineList = (params) => request('/offline/getList', 'GET', params);
export const getCouponByPhone = (params) => request('/admin/Coupon/getCouponByPhone', 'GET', params);
export const cancelCouponByPhone = (params) => request('/admin/Coupon/voidCoupon', 'POST', params);
export const giveProtonsBatch = (params) => request('/admin/User/giveProtonsBatch', 'POST', params);
export const saveAdutmconfig = (params) => request('/admin/Adutmconfig/save', 'POST', params);

// 面授课
export const getClassNoList = (params) => request('/admin/Classno/getClassnoList', 'GET', params);
export const getAllGroup = (params) => request('/Admin/Group/getAllGroup', 'GET', params);
export const saveClassNo = (params) => request('/admin/Classno/saveClassno', 'POST', params);
export const checkClassGroup = (params) => request('/admin/Classgroup/checkClassGroup', 'POST', params);
export const getClassNoItem = (params) => request('/admin/Classno/getClassnoItem', 'GET', params);
export const getAllTeacher = (params) => request('/Admin/Teacher/getAllTeacher', 'GET', params);
export const getAllPlace = (params) => request('/Admin/Place/getAllPlace', 'GET', params);
export const createAdvanced = (params) => request('/admin/Offline/createAdvanced', 'POST', params);
export const getSingleConflict = (params) => request('/admin/Classgroup/getSingleConflict', 'GET', params);
export const setOfflineMaterials = (params) => request('/admin/Material/setOfflineMaterials', 'POST', params);
// 上传开课文件
export const getLiveclassFile = (params) => request(`${gatewayUrl}/admin/v1/liveclass/getLiveclassFile`, 'POST', params);

// 兑换码
export const getActive = (params) => request('/admin/Coupon/getActive', 'GET', params);
export const exchangeCouponOne = (params) => request('/admin/Coupon/exchangeCouponOne', 'POST', params);
// 兑换码模版
export const createGenActiveCoupons = (params) => request('/admin/Coupon/genActiveCoupons', 'POST', params);

export const getSubclassList = (params) => request('/Subclass/getSubclassesByClassID', 'GET', params);
export const getExistenceCode = (params) => request('/admin/Coupon/getCoupons', 'GET', params);
// 当期座位列表
export const getSeatChartList = (params) => request('/admin/Class/edit', 'GET', params);
// 获取学期列表
export const getSemestersList = () => request('/admin/Class/getSemesters', 'GET');
// ?subject_type=&grade_type=&current_page=1&class_ids=10856,10858
export const getLive = (params) => request('/admin/Live/get', 'GET', params);
export const setSelfStudyRoom = (params) => request(`${gatewayUrl}/admin/v1/liveclass/setSelfStudyRoom`, 'POST', params);
export const saveRounds = (params) => request(`${gatewayUrl}/admin/v1/Teacher/saveRounds`, 'POST', params);
export const getStageList = (params) => request(`${gatewayUrl}/v3/schoolYear/getStageList`, 'GET', params);
export const getRoomUsers = (params) => request(`${gatewayUrl}/admin/v1/SelfStudyRoom/getRoomUsers`, 'GET', params);
export const uploadRoomUser = (params) => request(`${gatewayUrl}/admin/v1/SelfStudyRoom/uploadRoomUser`, 'POST', params);
// 学习卡管理
export const checkVipCard = (params) => request('/admin/Vipcard/check', 'POST', params);
export const updateVipCard = (params) => request('/admin/Vipcard/update', 'POST', params);
export const getVipCardList = (params) => request('/admin/Vipcard/getList', 'GET', params);
export const createVipCard = (params) => request('/admin/Vipcard/createVipCard', 'POST', params);
export const getVipCardLogs = (params) => request('/admin/Vipcard/getVipCardLogs', 'GET', params);
export const getVipCardClasses = (params) => request('/admin/Vipcard/getVipCardClasses', 'GET', params);
export const upVipImgs = (params) => request('/admin/Vipcard/upMainPicture', 'POST', params);
export const updateOnline = (params) => request('/admin/Vipcard/updateOnline', 'POST', params);
export const getRoundType = (params) => getJSON('https://static.eduzhixin.com/json/h5/admin/roundType.json');

// 用户管理->质子激励配置
export const updateExcitationConfig = (params) => request('/admin/ExcitationConfig/updateExcitationConfig', 'POST', params);
export const getUserExcitation = (params) => request('/admin/ExcitationConfig/getExcitationConfig', 'GET', params);
export const addExcitationConfig = (params) => request('/admin/ExcitationConfig/addExcitationConfig', 'POST', params);
export const getExcitationDataList = (params) => request('/admin/Excitationconfig/getExcitationDataList', 'GET', params);
export const uploadExcitationUserData = (params) => request('/admin/Excitationconfig/uploadUserData', 'POST', params);
export const giveAchievementBatch = (params) => request('/admin/Userachievement/giveAchievementBatch', 'POST', params);
export const giveAchievementOne = (params) => request('/admin/Userachievement/giveAchievementOne', 'POST', params);
export const getAllAchievements = (params) => request('/admin/Userachievement/getAllAchievements', 'GET', params);
export const updateDailyTaskDisplayed = (params) => request('/admin/ExcitationConfig/updateDailyTaskDisplayed', 'POST', params);
// 分配学号
export const allocateStudentNo = (params) => request('/admin/User/allocateStudentNo', 'POST', params);

// 系统管理->密钥对管理
export const getSecret = (params) => request('/admin/Secret/get', 'GET', params);
export const createSecret = (params) => request('/admin/Secret/create', 'POST', params);
export const disableSecret = (params) => request('/admin/Secret/disable', 'POST', params);
export const deleteSecret = (params) => request('/admin/Secret/delete', 'POST', params);
//
export const messageList = (params) => request('/admin/Notice/messageList', 'GET', params);
export const messageSend = (params) => request('/admin/Notice/messageSend', 'POST', params);
export const batchMessageSend = (params) => request('/admin/Notice/batchMessageSend', 'POST', params);
export const messageCancel = (params) => request('/admin/Notice/messageCancel', 'POST', params);
export const messageDel = (params) => request('/admin/Notice/messageDel', 'POST', params);

// 资料管理
export const getMaterial = (params) => request('/admin/Material/get', 'GET', params);
export const getMaterialAll = (params) => request('/admin/Material/getAll', 'GET', params);
export const updateMaterial = (params) => request('/admin/Material/update', 'POST', params);
export const getMaterialClass = (params) => request('/admin/Material/getLiveClass', 'GET', params);
// 资料管理->上传图片
export const saveMaterialCover = (params) => request('/admin/Material/saveCover', 'POST', params);
export const createMaterial = (params) => request('/admin/Material/create', 'POST', params);
// 质子商城->购买要求
export const getProtongoodslimit = (params) => request('/admin/Protongoodslimit/get', 'GET', params);
// 质子商城->购买要求（查看课程）
export const getProtongoodslimitClass = (params) => request('/admin/Protongoodslimit/getClassListById', 'GET', params);
// 质子商城->购买要求（查看考试）
export const getProtonGoodsLimitExam = (params) => request('/admin/Protongoodslimit/getExamListById', 'GET', params);
// 获取考试列表
export const getExamList = (params) => request('/admin/Exam/getExamList', 'GET', params);
// 质子商城->购买要求（新增）
export const createProtongoodslimit = (params) => request('/admin/Protongoodslimit/create', 'POST', params);
// 质子商城->购买要求（更新）
export const updateProtongoodslimit = (params) => request('/admin/Protongoodslimit/update', 'POST', params);
// 质子商城->商品管理
export const getProtongoods = (params) => request('/admin/Protongoods/get', 'GET', params);
// 质子商城->商品管理（新增）
export const createProtongoods = (params) => request('/admin/Protongoods/create', 'POST', params);
// 质子商城->商品管理（更新）
export const updataProtongoods = (params) => request('/admin/Protongoods/update', 'POST', params);
// 质子商城->商品管理（更新库存）
export const setQuotaProtongoods = (params) => request('/admin/Protongoods/setRestCount', 'POST', params);
// 质子商城->快递上传
export const UploadExpressInfo = (params) => request('/admin/Protonorder/uploadExpress', 'POST', params);
// 学院直播 -> 学校信息
export const createSchool = (params) => request('/admin/school/create', 'POST', params);
export const getSchool = (params) => request('/admin/School/getList', 'GET', params);
export const updateSchool = (params) => request('/admin/school/update', 'POST', params);
// 学院直播 -> 学习数据统计
export const liveStatistics = (params) => request('/admin/College/liveStatistics', 'GET', params);
export const getSchoolClasses = (params) => request('/admin/College/getSchoolClasses', 'GET', params);
// 报表预览
export const quickBiToken = (params) => request('/admin/Quickbi/token', 'GET', params);
// 视频管理 -> 新增
export const createVideomanage = (params) => request('/admin/Videoinfo/create', 'POST', params);
// 视频管理 -> 获取视频凭证
export const getVideoPlayAuth = (params) => request('/Video/getVideoPlayAuth', 'GET', params);
// 视频管理 -> 列表
export const getVideomanage = (params) => request('/admin/Videoinfo/getList', 'GET', params);
// 直播课程 -> 虚拟直播列表
export const getVirtualLiveList = (params) => request('/admin/Live/getVirtualLiveList', 'GET', params);
// 直播课程 -> 小课添加虚拟视频
export const addVirtualLive = (params) => request('/admin/Live/addVirtualLive', 'POST', params);
// 直播课程 -> 视频配置删除
export const deleteVideoinfo = (params) => request('/admin/Videoinfo/delete', 'GET', params);
// 直播课程 -> 新增弹幕包
export const addBulletPackage = (params) => request('/admin/Bullet/addBulletPackage', 'POST', params);
// 直播课程 -> 查看配置信息
export const getInfoBySubclass = (params) => request('/admin/Videoinfo/getInfoBySubclass', 'GET', params);
// 直播课程 -> 增加弹幕
export const addBullet = (params) => request('/admin/Bullet/add', 'POST', params);
// 直播课程 -> 查看弹幕/推送
export const getBulletPackageByVideo = (params) => request('/admin/Bullet/getBulletsByPackage', 'GET', params);
// export const getPushPackageById = (params) => request('http://yapi.upho2015.com/mock/17/v1/admin/Bullet/getBulletsByPackage', 'GET', params);
// 直播课程 -> 删除一条弹幕
export const deleteBullet = (params) => request('/admin/Bullet/delete', 'GET', params);
// 直播课程 -> 发送人列表
export const sendBulletUserList = (params) => request('/admin/User/getTestUser', 'GET', params);
// 直播课程 -> 清空弹幕包
export const setPackageEmpty = (params) => request('/admin/Bullet/setPackageEmpty', 'POST', params);
// 直播课程 -> 获取弹幕包
export const getPackages = (params) => request('/admin/Bullet/getPackages', 'GET', params);
// 直播课程 -> 弹幕包复制
export const packageCopy = (params) => request('/admin/Bullet/packageCopy', 'POST', params);
// 直播课程 -> 弹幕包共用
export const shareBulletPackage = (params) => request('/admin/Bullet/shareBulletPackage', 'POST', params);
// 直播课程 -> 解除弹幕包
export const resetSubclassBullet = (params) => request('/admin/Bullet/resetSubclassBullet', 'POST', params);
// 直播课程 -> 渠道预约配置
// export const getBookingUtmList = (params) => request('/admin/BookingUtm/getList', 'GET', params);
export const getBookingUtmList = (params) => request(`/admin/v1/BookingUtm/getList`, 'GET', params, LAPI_PREFIX);
export const addBookingUtm = (params) => request(`/admin/v1/BookingUtm/add`, 'POST', params, LAPI_PREFIX);
export const editBookingUtm = (params) => request(`/admin/v1/BookingUtm/edit`, 'POST', params, LAPI_PREFIX);
// 直播大课配置 -> 自习室
export const createSelfStudyRoom = (params) => request(`/v1/admin/Live/createSelfStudyRoom`, 'POST', params);
export const updateSelfStudyRoom = (params) => request(`/v1/admin/Live/updateSelfStudyRoom`, 'POST', params);
export const getSelfStudyRoomStudentList = (params) => request(`/admin/v1/SelfStudyRoom/getStudentList`, 'GET', params, LAPI_PREFIX);
// 直播大课配置 -> 创建直播课
export const createLiveAdvanced = (params) => request('/admin/Live/createAdvance', 'POST', params);
// 直播大课配置 -> 检查版课程详情
export const saveDescCheck = (params) => request('/admin/Live/saveDescCheck', 'POST', params);
// oss sts获取
export const getOssSts = (params) => request('/admin/oss/getJsonH5Sts', 'get', params);
// 管理员配置列表
export const getUseradmin = (params) => request('/admin/Useradmin/get', 'get', params);
// 添加管理员
export const createUseradmin = (params) => request('/admin/Useradmin/create', 'POST', params);
// 修改管理员配置
export const updateUseradmin = (params) => request('/admin/Useradmin/update', 'POST', params);
// 解除管理员配置
export const unfriendUseradmin = (params) => request('/admin/Useradmin/unfriend', 'get', params);
// 子账号列表
export const getSubaccounts = (params) => request('/admin/Useradmin/getSubaccounts', 'get', params);
// 子账号保存
export const saveSubaccounts = (params) => request('/admin/Useradmin/saveSubaccounts', 'POST', params);
// 面授课列表
export const getRefundList = (params) => request('/v1/admin/Offline/getOrders', 'GET', params);
// 面授课退款
export const getRefund = (params) => request('/v1/admin/Offline/refund', 'POST', params);
// 面授课转班
export const changeClass = (params) => request('/v1/admin/Offline/changeClass', 'POST', params);
// 面授课剩余名额&课次
export const getRemainClass = (params) => request('/v1/Offline/get', 'GET', params);
// 获取文章配置
export const getApparticle = (params) => request('/v1/admin/Apparticle/getList', 'GET', params);
export const changeLimit = (params) => request('/v1/admin/Apparticle/saveCoefficient', 'POST', params);
// 新增app文章配置
export const addApparticle = (params) => request('/v1/admin/Apparticle/add', 'POST', params);
// 修改app文章配置
export const updateApparticle = (params) => request('/v1/admin/Apparticle/update', 'POST', params);
// 删除app文章配置
export const deleteApparticle = (params) => request('/v1/admin/Apparticle/del', 'GET', params);
// app文章类型配置
export const getAppArticleTypeList = (params) => request('/admin/v1/AppArticleType/getList', 'GET', params, 'ProxyLapi');
export const postAppArticleTypeList = (params) => request('/admin/v1/AppArticleType/create', 'post', params, 'ProxyLapi');
export const deleteAppArticleTypeList = (params) => request('/admin/v1/AppArticleType/delete', 'post', params, 'ProxyLapi');
export const updateAppArticleTypeList = (params) => request('/admin/v1/AppArticleType/update', 'post', params, 'ProxyLapi');
// 修改直播大课 课程名称
export const saveSubjectName = (params) => request('/v1/admin/Classno/saveSubject', 'POST', params);
// 修改直播大课 班级名称
export const saveClassName = (params) => request('/v1/admin/Classgroup/saveName', 'POST', params);
export const getUtmconfig = (params) => request('/v1/admin/Utmconfig/list', 'GET', params);
export const addUtmconfig = (params) => request('/v1/admin/Utmconfig/add', 'POST', params);
export const editUtmconfig = (params) => request('/v1/admin/Utmconfig/edit', 'POST', params);
export const delUtmconfig = (params) => request('/v1/admin/Utmconfig/del', 'GET', params);
// 学习计划
export const findUsers = (params) => request('/v1/admin/User/findUsers', 'POST', params);
export const uploadStudyPlan = (params) => request('/v1/admin/User/uploadStudyPlan', 'POST', params);
export const delStudyPlan = (params) => request('/v1/admin/User/delPlan', 'GET', params);
// 用户信息-激活码用户
export const getActivationCodeStudentList = (params) => request('/v1/admin/ActivationCodeStudentList/get', 'GET', params);
export const deleteActivationCode = (params) => request('/v1/admin/ActivationCodeStudentList/delete', 'POST', params);
export const addActivationCode = (params) => request('/v1/admin/ActivationCodeStudentList/add', 'POST', params);
// 用户信息-聊天室黑名单
export const getLiveBlackList = (params) => request('/v1/admin/Chatroomblacklist/get', 'GET', params);
export const deleteLiveBlack = (params) => request('/v1/admin/Chatroomblacklist/delete', 'POST', params);
export const addLiveBlack = (params) => request('/v1/admin/Chatroomblacklist/add', 'POST', params);
// 平板管理-平板应用商店配置
export const getAppstoreList = (params) => request('/v1/admin/Appstore/getList', 'GET', params);
export const setAppstoreOnline = (params) => request('/v1/admin/Appstore/setOnline', 'POST', params);
export const getAppVersionList = (params) => request('/v1/admin/Appstore/getAppVersionList', 'GET', params);
export const createAppstore = (params) => request('/v1/admin/Appstore/create', 'POST', params);
export const updateAppstore = (params) => request('/v1/admin/Appstore/update', 'POST', params);
export const getAppstoreApkSts = (params) => request('/v1/admin/Oss/getAppstoreApkSts', 'GET', params);
export const createAppVersion = (params) => request('/v1/admin/Appstore/createAppVersion', 'POST', params);
// 投放管理-对话页
export const getPromotionPage = (params) => request('/v1/admin/promotionPage/get', 'GET', params);
export const getConversationByPromotionPageId = (params) => request('/v1/admin/promotionPage/getConversationByPromotionPageId', 'GET', params);
export const createPromotionPage = (params) => request('/v1/admin/promotionPage/create', 'POST', params);
export const updatePromotionPage = (params) => request('/v1/admin/promotionPage/update', 'POST', params);
export const addConversationMessages = (params) => request('/v1/admin/Promotionpage/saveConversationMessages', 'POST', params);
export const deleteConversation = (params) => request('/v1/admin/Promotionpage/deleteConversation', 'GET', params);
// 助教作业管理-用户列表
export const getStudentList = (params) => request(`${gatewayUrl}/ivx/forward/api/11301327/getUserStudents`, 'GET', params);
// 助教作业管理-作业列表
export const getAssignmentList = (params) => request('/v1/admin/Assignment/list', 'GET', params);
// 助教作业管理-布置作业
export const createAssignment = (params) => request('/v1/admin/Assignment/create', 'POST', params);
// 助教作业管理-编辑作业
export const updateAssignment = (params) => request('/v1/admin/Assignment/update', 'POST', params);
// 助教作业管理-批阅作业
export const reviewAssignment = (params) => request('/v1/admin/Assignment/review', 'POST', params);
// 生成图片-模版列表
export const getTemplateList = (params) => request('/v1/admin/Coursedetailsimage/get', 'GET', params);
// 生成图片-课程列表
export const getCourseList = (params) => request('/admin/Coursedetailsimage/getClassInfo', 'GET', params);
// 生成图片-创建模版
export const createImageTemplate = (params) => request('/v1/admin/Coursedetailsimage/create', 'POST', params);
// 生成图片-删除模版
export const deleteImageTemplate = (params) => request('/v1/admin/Coursedetailsimage/delete', 'POST', params);
export const saveImageConfigParams = (params) => request('/v1/admin/Coursedetailsimage/update', 'POST', params);
export const uploadCoverAndDetailsImage = (params) => request('/v1/admin/live/uploadCoverAndDetailsImage', 'POST', params);
// 定制课课程管理
export const getYueKeList = (params) => request(`${gatewayUrl}/ivx/forward/api/11012531/chdry4ra3j500006pqg0`, 'GET', params);
export const getYueKeSubclassList = (params) => request(`${gatewayUrl}/ivx/forward/api/11012531/subclass`, 'GET', params);
export const getMultipleSubclass = (params) => request(`${gatewayUrl}/v3/1on1/getMultipleSubclass`, 'GET', params);
export const get1on1ReplayVideoSts = (params) => request(`${gatewayUrl}/admin/v1/oss/get1on1ReplayVideoSts`, 'GET', params);
export const deleteVideoSubClass = (params) => request(`${gatewayUrl}/admin/v1/oss/del1on1ReplayVideo`, 'post', params);
// 获取企微部门列表
export const getDepartments = (params) => request(`/v1/admin/Departments/get`, 'GET', params);
export const setDepartment = (params) => request(`/v1/admin/Role/setDepartment`, 'POST', params);

// 获取oss上传凭证
export const getStsAuth = (params) => request('/v1/admin/Oss/getStsAuth', 'GET', params);
// 分配小课地点
export const getAllocationPlace = (params) => request(`${gatewayUrl}/admin/v1/Subclass/allocationPlace`, 'post', params);
// 更新小课地点信息
export const updatePlaces = (params) => request(`${gatewayUrl}/admin/v1/Subclass/updatePlaces`, 'post', params);
// 清理登录频繁
export const clearLoginFrequent = (params) => request(`${gatewayUrl}/admin/v1/user/clearLoginFrequent`, 'post', params);
// 运单查询
export const getExpress = (params) => request(`/v1/admin/Express/get`, 'GET', params);
// 查询角色
export const getRole = (params) => request(`/v1/admin/Role/index`, 'GET', params);
// 新增角色
export const addRole = (params) => request(`/v1/admin/Role/save`, 'post', params);
// 权限目录
export const getPermissionsTree = (params) => request(`/v1/admin/Role/getPermissionsTree`, 'GET', params);
// 获取权限
export const getRolePermissions = (params) => request(`/v1/admin/Role/getRolePermissions`, 'GET', params);
// 设置权限
export const setRolePermissions = (params) => request(`/v1/admin/Role/setRolePermissions`, 'post', params);
//
// 宣传配置-推荐讲座
export const getRecommendClassList = (params) => request(`${gatewayUrl}/admin/v1/RecommendClass/getList`, 'GET', params);
export const sortRecommendClass = (params) => request(`${gatewayUrl}/admin/v1/RecommendClass/sort`, 'POST', params);
export const deleteRecommendClass = (params) => request(`${gatewayUrl}/admin/v1/RecommendClass/delete`, 'POST', params);
export const addRecommendClassConfig = (params) => request(`${gatewayUrl}/admin/v1/RecommendClass/add`, 'POST', params);
export const updateRecommendClassConfig = (params) => request(`${gatewayUrl}/admin/v1/RecommendClass/update`, 'POST', params);
// 宣传配置-app轮播图配置
export const getAppCarouselChartList = (params) => request(`${gatewayUrl}/admin/v1/AppCycleplay/getList`, 'GET', params);
export const sortAppCarouselChart = (params) => request(`${gatewayUrl}/admin/v1/AppCycleplay/sort`, 'POST', params);
export const deleteAppCarouselChart = (params) => request(`${gatewayUrl}/admin/v1/AppCycleplay/delete`, 'POST', params);
export const addAppCarouselChartConfig = (params) => request(`${gatewayUrl}/admin/v1/AppCycleplay/add`, 'POST', params);
export const updateAppCarouselChartConfig = (params) => request(`${gatewayUrl}/admin/v1/AppCycleplay/update`, 'POST', params);
// 大月考配置-助教管理
export const getMeAssistantList = (params) => request(`/admin/v1/MeAssistant/getList`, 'GET', params, LAPI_PREFIX);
export const deleteMeAssistant = (params) => request(`/admin/v1/MeAssistant/delete`, 'POST', params, LAPI_PREFIX);
export const addMeAssistant = (params) => request(`/admin/v1/MeAssistant/batchAdd`, 'POST', params, LAPI_PREFIX);
export const updateMeAssistant = (params) => request(`/admin/v1/MeAssistant/update`, 'POST', params, LAPI_PREFIX);
// 大月考配置-月考判卷招募信息
export const getMeRecruitList = (params) => request(`${gatewayUrl}/admin/v1/MeRecruit/getList`, 'GET', params);
export const switchOnlineMeRecruit = (params) => request(`${gatewayUrl}/admin/v1/MeRecruit/switchOnline`, 'GET', params);
export const getMonthlyExamList = (params) => request(`${gatewayUrl}/admin/v1/MonthlyExam/getList`, 'GET', params);
export const addMeRecruit = (params) => request(`${gatewayUrl}/admin/v1/MeRecruit/add`, 'POST', params);
export const updateMeRecruit = (params) => request(`${gatewayUrl}/admin/v1/MeRecruit/update`, 'POST', params);
// 质子炼丹
export const getAlchemyLiveEnvList = (params) => request(`${gatewayUrl}/admin/v1/AlchemyLiveEnv/getList`, 'GET', params);
export const getAlchemyElementList = (params) => request(`${gatewayUrl}/admin/v1/AlchemyElement/getList`, 'GET', params);
export const addAlchemyLiveEnv = (params) => request(`${gatewayUrl}/admin/v1/AlchemyLiveEnv/add`, 'POST', params);
export const updateAlchemyLiveEnv = (params) => request(`${gatewayUrl}/admin/v1/AlchemyLiveEnv/update`, 'POST', params);
export const addAlchemyElement = (params) => request(`${gatewayUrl}/admin/v1/AlchemyElement/add`, 'POST', params);
export const updateAlchemyElement = (params) => request(`${gatewayUrl}/admin/v1/AlchemyElement/update`, 'POST', params);
export const deleteAlchemyElement = (params) => request(`${gatewayUrl}/admin/v1/AlchemyElement/delete`, 'POST', params);
// 生成账号
export const extendCollegeUser = (params) => request(`/v1/admin/User/extendCollegeUser`, 'POST', params);
export const getCollegeUserList = (params) => request(`/v1/admin/User/getCollegeUserList`, 'GET', params);
